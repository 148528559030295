import Head from "next/head";
import DynamicPage from "../components/dynamic-page";
import { GetServerSidePropsContext } from "next";
import {
  getAgencyIdFromRequest,
  getLanguageFromRequest,
  getTranslation,
} from "../utils";
import sdk from "../sdk";
import { AgencyWebsiteSection } from "@serviestate/shared/types";
import { useAgency } from "../hooks/use-agency";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import { track } from "../tracking";

export async function getServerSideProps({
  req,
  resolvedUrl,
}: GetServerSidePropsContext) {
  const locale = getLanguageFromRequest(req);
  const agencyId = getAgencyIdFromRequest(req);

  const section = await sdk.website.renderSection({
    agency_id: agencyId,
    section_slug: "home",
  });

  track(req, resolvedUrl, "home");

  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
      section,
    },
  };
}

export default function Home({ section }: { section: AgencyWebsiteSection }) {
  const { i18n } = useTranslation();
  const agency = useAgency();

  const title = getTranslation(
    i18n.language,
    section.config.title as Record<string, string>
  );

  const description = getTranslation(
    i18n.language,
    section.config.description as Record<string, string>
  );

  const pageTitle = title ? `${title} - ${agency.name}` : agency.name;

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        {description && <meta name="description" content={description} />}
      </Head>
      <DynamicPage components={section.config.components ?? []} />
    </>
  );
}
